import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import ArrowIcon from 'components/atoms/ArrowIcon';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ResourceGrid from 'components/molecules/ResourceGrid';
import CtaSection from 'components/organisms/CtaSection';
import FullWidthResourceCard from 'components/organisms/FullWidthResourceCard';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const EventsContent = ({ hero, newsletterCta, cta, resources = [] }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <div className="u-container pt-32 md:pt-48 mb-12 md:mb-20 px-6 lg:px-md xl:px-0">
        <Link
          className="inline-flex items-center text-sk-blue font-semibold text-lg tracking-tighter transition hover:text-dark-grey focus:text-dark-grey"
          to="/resources"
        >
          <ArrowIcon className="transform rotate-180 mr-4 -mt-px" /> Back to
          Resources
        </Link>
        <h1 className="u-h1 mt-6 md:mt-8 mb-4">{hero.title}</h1>
        <p className="text-xl leading-8 tracking-tighter w-120 max-w-full">
          {hero.description}
        </p>
      </div>

      {resources
        .slice(0, 1)
        .map(({ title, date, location, thumbnailUrl, url, excerpt }, index) => (
          <FullWidthResourceCard
            key={index}
            title={title}
            date={date}
            imageUrl={thumbnailUrl}
            url={url}
            type="event"
            description={excerpt}
            eventLocation={location}
          />
        ))}

      <NewsletterCtaSection
        headline={newsletterCta?.headline}
        background="bg-sk-blue text-white"
      />

      {resources.length > 1 && (
        <ResourceGrid
          cols={3}
          noTag
          resources={resources.slice(1, showAll ? undefined : 5)}
        />
      )}

      {resources.length > 5 && !showAll && (
        <div className="pt-20 pb-36 text-center">
          <button
            className="bg-dark-grey text-white py-5 px-10 text-lg tracking-tighter leading-none hover:opacity-75 focus:opacity-75 transition rounded-full font-semibold"
            onClick={() => setShowAll(true)}
          >
            Load more
          </button>
        </div>
      )}

      <CtaSection headline={cta?.headline} />
    </>
  );
};

const RESOURCES_FIXTURE = [
  {
    title: 'Event 1',
    date: '2020-05-06T07:00:32.311Z',
    location: 'remote',
    thumbnailUrl: '/uploads/video-labor-day.jpg',
    url: '/events',
    type: 'event',
    excerpt:
      'Mauris aliquet varius nec vitae. Malesuada parturient aliquet auctor nunc eleifend suscipit viverra. Mattis facilisis viverra turpis.',
  },
];

export const EventsPreview = ({ entry }) => {
  return (
    <EventsContent
      {...entry.get('data').toJS()}
      resources={RESOURCES_FIXTURE}
    />
  );
};

const EventsPage = ({
  data: {
    markdownRemark: { frontmatter },
    events,
  },
}) => {
  const { seo } = frontmatter;

  const resources = events.edges.map(({ node }) => {
    const { title, date, location, heroImage, slug, description } =
      node.frontmatter || {};

    return {
      title,
      date,
      location,
      thumbnailUrl: heroImage,
      url: `/events/${slug}`,
      type: 'event',
      excerpt: description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <EventsContent {...frontmatter} resources={resources} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query EventsPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/events/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    events: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "event" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            location
            heroImage
            description
          }
        }
      }
    }
  }
`;

export default EventsPage;
